<template>
	<div class="flexPage darkPage">
		<div class="currencyHead fixed themeHead">
			<navbar :params="pageInfo">
				<template #right>
					<img src="../../assets/img/record.png" alt="" style="width: 24px;height: 24px;">
				</template>
			</navbar>

		</div>
		<div class="flexContent">
			<!-- 表单 -->
			<div class="form">
				<!-- 选择币种 -->
				<div class="cellGroup">
					<div class="tbTitle1">{{ $t('other.translate71') }}</div>
					<div style="display: flex;align-items: center;margin-top: 8px;">
						<img src="../../assets/img/USDT.png" alt="" style="width: 32px;height: 32px;margin-right: 8px;">
						<span class="usdtName">USDT - ERC20</span>
					</div>
				</div>
				<!-- 提现地址 -->
				<div class="cellGroup" style="margin-top: 48px;">
					<div class="tbTitle">{{ $t('form.assets.tbAddr') }}</div>
					<van-field v-model="form.address" :placeholder="$t('form.assets.p3')"></van-field>
				</div>
				<!-- 提币数量 -->
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.assets.tbNum') }}</div>
					<van-field v-model.number="withdrawAmount" :placeholder="$t('form.assets.p4')" type="number" min="0"
						@focus="computerAmount" @input="() => {
							if (withdrawAmount < 0 || withdrawAmount == '') withdrawAmount = 0;
						}
							" />
					<div
						style="width: 100%;display: flex;justify-content: flex-end;color: #00000080;margin-top: -1px;height: 20px;">
						{{ $t('trade.use') }}：{{
							balance }}</div>
				</div>
				<!-- 资金密码 -->
				<div class="cellGroup">
					<div class="tbTitle">{{ $t('form.assets.tradePsw') }}</div>
					<van-field v-model="form.password" type="password" :placeholder="$t('form.assets.p5')" />
					<div
						style="width: 100%;display: flex;justify-content: flex-end;color: #00000080;margin-top: -1px;height: 20px;">
						{{ $t('form.assets.fee') }}: 1%</div>
				</div>
				<!-- 验证码 -->
				<!-- <div class="cellGroup">
					<div class="tbTitle">{{ $t('form.assets.email') }}</div>
					<van-field v-model="form.code" :placeholder="$t('form.assets.p6')">
					</van-field>
					<button class="btn-code" :disabled="countingDown" @click="sendSms('email')">{{ buttonText
						}}</button>
				</div> -->
				<!-- 手续费 -->
				<!-- <div class="cellGroup">
					<div class="tbTitle">{{ $t('form.assets.fee') }}</div>
					<van-field class="sjAcount" placeholder="" :value="`${withdrawFee}${currentCoin.name}`" disabled />
				</div> -->
			</div>

		</div>

		<van-tabbar>
			<van-tabbar-item>
				<van-button style="width: 343px;height: 44px;background-color: #000000;color: #fff;border-radius: 8px;"
					:loading="submiting" @click="submit()">{{
						$t('button.assets.sure') }}</van-button>
			</van-tabbar-item>
		</van-tabbar>

		<!-- 选择币种 -->
		<van-action-sheet v-model="chooseCoin.coin" :cancel-text="$t('button.otc.cancel')" close-on-click-action
			:actions="coinList" @select="selectCoin" />

		<!-- 选择地址 -->
		<van-action-sheet v-model="chooseCoin.address" :cancel-text="$t('button.otc.cancel')" close-on-click-action
			:actions="currentCoin.addresses" @select="selectAddress" />
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";
import store from "./store/common"
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t('assets.index.n2'),
				url: '/withdrawalHistory',
				value: '',
				icon: 'orders-o',
				navbarStyle: 'normal'
			},
			submiting: false,
			chooseCoin: {
				coin: false,
				address: false
			},
			form: {
				coin: '',
				address: '',
				password: '',
				code: '',
				googleCode: '',
				tag: ''
			},
			coinList: [],
			currentCoin: { addresses: [] },
			withdrawAmount: 0, // 默认提币数量
			withdrawFee: 0, // 默认提币手续费
			withdrawFeeFold: 0, // 倍率
			walletUSDT: {},
			balance: 0,

			countingDown: false,
			countdownSeconds: 60
		}
	},
	components: { navbar },
	watch: {
		currentCoin: function () {
			this.withdrawFee = this.withdrawAmount * this.currentCoin.txFeeRatio > this.currentCoin.txFee ? this.withdrawAmount * this.currentCoin.txFeeRatio : this.currentCoin.txFee;
		},
		withdrawAmount() {
			var str = Number(this.withdrawFeeFold / 100).toFixed(1);
			this.withdrawFee = this.withdrawAmount * str > this.currentCoin.txFee ? this.withdrawAmount * str : this.currentCoin.txFee;
		},
		currentCoin(newValue, oldValue) {
			if (newValue.addresses.length > 0) {
				newValue.addresses = newValue.addresses.map((item) => {
					return {
						...item,
						name: `${item.remark}(${item.address})`
					};
				});
				this.currentCoin.addresses = newValue.addresses;
			} else {
				this.currentCoin.addresses.push({ name: '无数据' });
			}
		}
	},
	computed: {
		buttonText() {
			return this.countingDown ? `${this.$t('button.assets.reSend')} ${this.countdownSeconds}s` : this.$t('button.assets.sendSms');
		},
	},
	mounted() {
	},
	created() {
		this.getAddrList();
		this.getWallet(store.state.currency.coin.name);
	},
	methods: {
		clearValues() {
			if (this.$refs.address) {
				this.$refs.address.setQuery(' ');
			}
			this.withdrawAdress = '';
			this.inputAddress = '';
			this.withdrawAmount = 0;
			// this.withdrawFee= 0;
			this.withdrawOutAmount = 0;
		},
		getAddrList() {
			//初始化页面上的值
			this.clearValues();
			//获取地址
			this.$http.post(this.host + '/uc/withdraw/support/coin/info').then((response) => {
				var resp = response.body;
				if (resp.code == 0 && resp.data.length > 0) {
					this.coinList = resp.data;
					if (this.coinList.length) {
						this.currentCoin = this.coinList[0];
						this.form.coin = this.currentCoin.name;
					}
				} else {
					this.$toast(resp.message);
				}
			});
		},
		// 获取币币余额 coinUnit为币种
		getWallet(coinUnit) {
			this.$http.post(this.host + '/uc/asset/spotWallet/' + coinUnit).then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					this.withdrawFeeFold = resp.data.coin.txFeeRatio
					this.walletUSDT = resp.data;
					this.balance = this.walletUSDT.balance ? this.walletUSDT.balance : 0;
				} else {
					this.$toast(resp.message);
				}
			});
		},
		computerAmount() {
			// 否则 提出当前数量减去手续费
			this.withdrawOutAmount = Number((this.withdrawAmount - this.withdrawFee).toFixed(5));
		},
		// 选择币种
		selectCoin(val) {
			this.form.coin = val.name;
			this.clearValues();
			for (let i = 0; i < this.coinList.length; i++) {
				if (val.name == this.coinList[i].name) {
					this.currentCoin = this.coinList[i];
					break;
				}
			}

		},
		// 选择地址
		selectAddress(val) {
			this.form.address = val.address ? val.address : '';
		},
		// 发送验证码
		sendSms(type) {
			this.$http.post(this.host + 'uc/email/withdraw/code').then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					this.$toast(this.$t('message.emailSuc'));
					this.countingDown = true;
					const timer = setInterval(() => {
						this.countdownSeconds--;
						if (this.countdownSeconds === 0) {
							clearInterval(timer);
							this.countingDown = false;
							this.countdownSeconds = 60;
						}
					}, 1000);
				} else {
					this.countingDown = false;
					this.$toast(resp.message);
				}
			});
		},
		submit() {
			// if (!this.form.address) return this.$toast(this.$t('form.assets.p3'));
			// if (!this.withdrawAmount || this.withdrawAmount <= 0) return this.$toast(`${this.$t('message.assets.t5')}0`);
			// if (!this.form.password) return this.$toast(this.$t('form.assets.p5'));
			// if (!this.form.code) return this.$toast(this.$t('form.assets.p6'));
			if (this.withdrawAmount < this.withdrawFee) return this.$toast('提币数量不得小于手续费');
			let params = {};
			params['unit'] = 'USDT';
			params['address'] = this.form.address;
			params['amount'] = this.withdrawAmount;
			params['fee'] = this.withdrawFee;
			params['jyPassword'] = this.form.password;
			params['code'] = this.form.code;
			params['googleCode'] = this.form.googleCode;
			params['tag'] = this.form.tag;
			this.$http.post(this.host + '/uc/withdraw/apply', params).then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					this.form.code = '';
					this.form.password = '';
					this.clearValues();
					this.$toast(resp.message);
				} else {
					this.$toast(resp.message);
				}
			});
		}
	}
}
</script>
<style scoped>
::v-deep .van-cell {
	width: 109% !important;
	margin-left: -16px !important;
	border: none;
}

::v-deep .van-field__control {
	border: none;
	border-bottom: 2px solid #D9D9D9;
}

.ml15 {
	margin-left: 10.1781vw;
}

.usdtName {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.cellGroup .tbTitle {
	min-width: 22.9008vw;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;

}

.cellGroup .tbTitle1 {
	min-width: 22.9008vw;
	color: #0000004D
}

/* .cellGroup span {
	color: #515a6e;
	margin-left: 1.2723vw;
	font-size: 3.5623vw;
} */

.cellGroup .van-field__control {
	margin-bottom: unset;
}

.van-tabbar {
	height: 20.3562vw;
}

.van-tabbar-item__text {
	width: 90vw;
}

.btn-code {
	margin-right: 4.0712vw;
	box-sizing: border-box;
	border: 1px solid #3861FB;
	color: #3861FB;
	font-size: 3.5623vw;
}

.form {
	width: 100%;
	padding: 0 16px;
	padding-top: 14px;
}
</style>
